import axios from 'axios'
import { getToken } from '../getToken'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('929fc8bfe09fdbb3395a45d610e199e8e25f119c43ea5f8bfef2edf778ab48bd')
// const baseUrl = 'https://improve-bot.delightfulbush-65d4f852.westus3.azurecontainerapps.io/929fc8bfe09fdbb3395a45d610e199e8e25f119c43ea5f8bfef2edf778ab48bd'

export const getProfileData = async () => {
  const { data } = await axios.get(baseUrl, {
    headers: {
      token: getToken()
    }
  })
  if (data.rut) {
    data.rut = data.rut.trim() 
  }

  return data
}