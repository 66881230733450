import axios from 'axios'
import { getToken } from '../getToken' 
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('4ff1be716838d6b4a18dc9021cdcb5979f1287652f19394718370fa0a154b674')
// const baseUrl = 'https://improve-bot.delightfulbush-65d4f852.westus3.azurecontainerapps.io/4ff1be716838d6b4a18dc9021cdcb5979f1287652f19394718370fa0a154b674'

export const getSuscription = async () => {
  const { data } = await axios(baseUrl, {
    headers: {
      token: getToken()
    }
  })

  return data
}