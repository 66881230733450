import axios from 'axios'
import { getToken } from './getToken'
import { apiUrl } from './utils/apiUrl'

const baseUrl = apiUrl(`03a35a7c2bb5961c20d1441eabbd2d4458e73214a07412f9aca863807fdbdf53/${getToken()}/`)

export const getKrinoClientValidation = async () => {
  const { data } = await axios.get(baseUrl)
  const valid = data.permiso === 1 || data.permiso === 2 
  console.log("id cliente", data.id_cliente)
  console.log("id cliente", data)
  return { valid, permiso: data.permiso, name_perfil: data.name_perfil, user_id: data.user_id, client_id:data.id_cliente }
}