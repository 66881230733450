import axios from 'axios'
import { getToken } from '../getToken'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('70498a184dbffc79a9c4a9aa3dd84c831b0e83b4fe13b25b364cf87f68a7b117')
// const baseUrl = 'https://improve-bot.delightfulbush-65d4f852.westus3.azurecontainerapps.io/70498a184dbffc79a9c4a9aa3dd84c831b0e83b4fe13b25b364cf87f68a7b117'

export const disableMetabaseLink = async (LinkId) => {
  const { data } = await axios.put(baseUrl, {
    id: LinkId
  },
  {
    headers: {
      token: getToken()
    }
  })

  return data
}