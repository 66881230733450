import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// import KrinoLogo from '../assets/img/K-Logo-small.png'
// import KrinoLogowhite from '../assets/img/K-white-Logo-small.png'
import KrinoLogo from '../assets/img/Krino-Blanco_1.png'
import { updateMenusAction } from '../Store/Actions/menus/updateMenusAction'

import {
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import EditProfileModal from './Modals/EditProfileModal'
import SettingsModal from "./Modals/SettingsModal"
import { Link, useHistory } from 'react-router-dom'
// foto avatar
import profileAvatar from '../assets/img/profile_photo.svg'

import { userLogout } from '../Store/Actions/userLogout'
// import { toggleThemeAction } from '../Store/Actions/toggleThemeAction'
import { getMenusByProfile } from '../services/menus/getMenusByProfile'
import { toggleMovileMenu } from '../utils/toggleMovileMenu'
import { Tour } from './Components/Tour'
import menuSteps from '../utils/tourSteps/menu'
import { KrinoConfigModal } from './Modals/KrinoConfigModal'
import { isTokenExpired } from '../utils/isTokenExpired'
import { useValidateViewPermissions } from '../hooks/permissions/useValidateViewPermissions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
const MySwal = withReactContent(Swal)

const NavigationItemView = ({ item, tooltipName, url, id = '', isBig}) => {
  // const {selectedSidebar} = useSelector(state => state);
  const [showSubMenu, setShowSubMenu] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const history = useHistory()

  const handleShowMenu = () => {
    setShowSubMenu(!showSubMenu)
  }

  const toggle = () => {
    if (isBig) {
      setTooltipOpen(false)  
    } else {
      setTooltipOpen(!tooltipOpen)
    }
  }

  const handleSelectOption = (url) => {
    history.push(url)
  }

  if (item.Dropdown) {
    return (
      <li id={id} className={`navigationItem navigationItem--dropdown ${isBig ? 'navigationItem--big' : ''}`}>

        <Dropdown isOpen={showSubMenu} toggle={handleShowMenu}>
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={showSubMenu}
          >
            <button
              id={tooltipName}
              className='dropdownButton'
              onClick={handleShowMenu}
            >
              {item.icon}
              {isBig && item.name}
              
            </button>
            <span className='icon_ChevronDown'>
              <FeatherIcon.ChevronDown />
            </span>
          </DropdownToggle>
          <DropdownMenu>
            {
              item.content.map((option, index) => {
                return (
                  <DropdownItem
                    key={`subMenuItem-${index}-${id}`}
                    onClick={() => handleSelectOption(option.url)}
                  >
                    <span>
                      {option.name}
                    </span>
                  </DropdownItem>
                )
              }) 
            }
            
          </DropdownMenu>
        </Dropdown>
        <Tooltip
          placement="right"
          isOpen={tooltipOpen}
          target={tooltipName}
          toggle={toggle}>
          {item.name}
        </Tooltip>

      </li>
    )
  }

  return (
    <li id={id} className={`navigationItem ${isBig ? 'navigationItem--big' : ''}`}>
      <Link
        onClick={toggleMovileMenu}
        to={url}
        href={item.name}
        // className={`sidebar ${selectedSidebar === item.name ? 'active' : ''}`}
        id={tooltipName}
      >
        {item.badge && <span className={"badge badge-" + item.badge}>&nbsp;</span>}
        {item.icon}
        {isBig && item.name}
      </Link>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={tooltipName}
        toggle={toggle}>
        {item.name}
      </Tooltip>
    </li>
  )
};

function Navigation() {

  const [bigMenu, setBigMenu] = useState(false) 
  const history = useHistory()
  const [runTour, setRunTour] = useState(false)
  const menus = useSelector(state => state.menus)
  const dispatch = useDispatch()

  const user = useSelector(store => store.user)

  const [userMenuTooltipOpen, setUserMenuTooltipOpen] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const userMenuToggle = () => {
    return !dropdownOpen && setUserMenuTooltipOpen(!userMenuTooltipOpen);
  };

  const toggle = () => setDropdownOpen(prevState => {
    setUserMenuTooltipOpen(false);
    return !prevState;
  });

  const editModalToggle = () => setEditModalOpen(!editModalOpen);

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const settingsModalToggle = () => setSettingsModalOpen(!settingsModalOpen);

  const [openKrinoModal, setOpenKrinoModal] = useState(false)

  const krinoModalToggle = () => {
    setOpenKrinoModal(!openKrinoModal)
  }

  useEffect(() => {
    let mounted = true

    if (!menus.length) {
      getMenusByProfile()
        .then(data => {
          if (mounted) {
            dispatch(updateMenusAction(data))
            setRunTour(true)
          }
        })
    }
    return () => {
      mounted = false
    }
  }, [user, dispatch, menus])

  useEffect(() => {
    if (isTokenExpired(2)) {
      MySwal.fire({
        title: 'Tu sesión ha expirado',
        text: "por favor inicia sesión nuevamente",
        icon: 'warning',
      })
      dispatch(userLogout())
    }
  }, [dispatch])

  const MenuTable = {
    'home': {
      name: 'Inicio',
      icon: <FeatherIcon.Home/>,
      url: '/',
      order: 1
    },
    "Dashboard": {
      name: 'Métricas',
      icon: <FeatherIcon.BarChart2/>,
      url: '/dashboard/',
      order: 2
    },
    "Chats": {
      name: 'Live-Chat de soporte',
      icon: <FeatherIcon.MessageCircle/>,
      url: '/chat/',
      order: 3
    },
    "leadControlPanel": user.isKrinoClient ? {
      name: 'Temperatura de lead',
      icon: <FeatherIcon.Thermometer />,
      Dropdown: true,
      order: 4,
      content: [
        {
          name: 'Panel de control',
          url: '/lead-control-panel/'
        },
        {
          name: 'Modelos',
          url: '/lead-temperature-models/'
        },
      ]
    } : {
      name: 'Temperatura de lead',
      icon: <FeatherIcon.Thermometer/>,
      url: '/lead-control-panel/',
      order: 4
    },
    "Campañas": {
      name: 'Campañas',
      icon: <FeatherIcon.Flag/>,
      url: '/campaign/',
      order: 5
    },
    "Plantillas": {
      name: 'Plantillas',
      icon: <FeatherIcon.Layout/>,
      url: '/templates/',
      order: 6
    },
    'Builder': {
      name: 'bots',
      icon: <FeatherIcon.Grid/>,
      url: '/bot-builder/',
      order: 7
    },
    'Builderv2': {
      name: 'botsv2',
      icon: <FeatherIcon.Cpu/>,
      url: '/bot-builder-v2/',
      order: 7
    },
    "faqSection": {
      name: 'Flujos de bots',
      icon: <FeatherIcon.Server/>,
      url: '/bot-sections/',
      order: 8
    },
    "crm": {
      name: 'Kanban',
      icon: <FeatherIcon.Book/>,
      url: '/crm/',
      order: 10
    },
    
    "Suscripción": {
      name: 'Suscripción',
      icon: <FeatherIcon.DollarSign/>,
      Dropdown: true,
      order: 11,
      content: [
        {
          name: 'Planes',
          url: '/payment/'
        },
        {
          name: 'Facturación',
          url: '/billing/'
        },
      ]
    },
    "tutoriales": {
      name: 'Tutoriales',
      icon: <FeatherIcon.Youtube/>,
      url: '/tutorials/',
      order: 12
    },
    "perfilConfig": {
      name: 'Configurar Perfiles',
      icon: <FeatherIcon.UserCheck />, 
      url: '/profile-config/',
      order: 13  
    }
    // "conversaciones": {
    //   name: 'Conversaciones',
    //   icon: <FeatherIcon.Clipboard/>,
    //   url: '/conversations/',
    //   order: 8
    // },

    // "data": { // seccion de data comentada por el momento
    //   name: 'Datos',
    //   icon: <FeatherIcon.Database />,
    //   Dropdown: true,
    //   order: 8,
    //   content: [
    //     {
    //       name: 'conversaciones',
    //       url: '/conversations/'
    //     },
    //     {
    //       name: 'Datos',
    //       url: '/dataTable/'
    //     },
    //   ]
    // },

    // "data": {
    //   name: 'Datos',
    //   icon: <FeatherIcon.Database/>,
    //   url: '/dataTable/',
    //   order: 9
    // },
  }
  // para testear menus. dejar solo menu.map al terminar
  // const menuTest = [...menus, {name_menu: 'home', id_menu:0}]

  let formatedMenus = menus.map(menu => {
    return {...MenuTable[menu.name_menu]}
  })

  formatedMenus = formatedMenus.filter(menu => Object.keys(menu).length)

  const navigationItems = formatedMenus.sort((a, b) => a.order - b.order)
  // hace la redireccion si se intenta entrar a una vista a la que no se tienen permisos
  useValidateViewPermissions(navigationItems, MenuTable)

  return (
    <nav className="navigation">
      <Tour
        run={runTour && false}
        steps={menuSteps}
      />
      <KrinoConfigModal modal={openKrinoModal} toggle={krinoModalToggle}/>
      <EditProfileModal modal={editModalOpen} toggle={editModalToggle}/>
      <SettingsModal modal={settingsModalOpen} toggle={settingsModalToggle}/>
      <div className={`nav-group ${bigMenu ? 'nav-group--big' : ''}`} >
        <ul className='krino_scrollbar krino_scrollbar--white'>
          <li
            className={`logoContainer ${bigMenu ? 'logoContainer--big' : ''}`}
            id='menu-0'
          >
            <Link to="/">
              <img src={KrinoLogo} alt="Krino logo" />
            </Link>
            <button onClick={() => {setBigMenu(!bigMenu)}}>
              {
                bigMenu && (
                  <FeatherIcon.ChevronLeft />
                )
              }
              <FeatherIcon.Menu />
              {
                !bigMenu && (
                  <FeatherIcon.ChevronRight />
                )
              }
            </button>
          </li>
          <div className='navigation__menusContainer krino_scrollbar krino_scrollbar--white'>
            {
              navigationItems.map((item, i) => {
                if (item === null) return null
                return (
                  <NavigationItemView
                    isBig={bigMenu}
                    id={`menu-${i + 1}`}
                    key={i}
                    item={item}
                    tooltipName={"Tooltip-" + i}
                    url={item.url}
                  />
                )
              })
              
            }
          </div>
          <div>
            {/* <li className="">
              <a href="#/" onClick={(e) => darkSwitcherToggle(e)} className="dark-light-switcher"
                id="dark-switcher">
                <FeatherIcon.Moon/>
              </a>
              <Tooltip
                placement="right"
                isOpen={darkSwitcherTooltipOpen}
                target="dark-switcher"
                toggle={darkSwitcherTooltipToggle}
              >
                Modo oscuro
              </Tooltip>
            </li> */}
            <li id="user-menu" className="text-center">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                >
                  <figure className="avatar" style={{background: 'white', cursor: 'pointer'}}>
                    <img src={profileAvatar} className="rounded-circle" alt="avatar"/>
                  </figure>
                </DropdownToggle>
                <DropdownMenu>
                  {
                    user.isKrinoClient && (
                      <DropdownItem onClick={() => {
                        history.push('/adminmenu/')
                      }}
                      >
                        Administración
                      </DropdownItem>
                    )
                  }
                  <DropdownItem divider/>
                  <DropdownItem onClick={() => history.push('/profile/')}>
                    Ver perfil
                  </DropdownItem>
                  {/* <DropdownItem onClick={profileActions}>Profile</DropdownItem> */}
                  {/* <DropdownItem onClick={editModalToggle}>Edit profile</DropdownItem> */}
                  {/* <DropdownItem onClick={settingsModalToggle}>Settings</DropdownItem> */}
                  <DropdownItem divider/>
                  <DropdownItem
                    onClick={() => {dispatch(userLogout())}}
                  >
                    Cerrar sesión
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Tooltip
                placement="right"
                isOpen={userMenuTooltipOpen}
                target="user-menu"
                toggle={userMenuToggle}>
                  Menu de usuario
              </Tooltip>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  )
}

export default Navigation
