import axios from 'axios'
import { getToken } from '../getToken'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('ac896cc987e2b4431e80e874471eb3dd50f4763a18f251c09b09c44c123858cf')
// const baseUrl = 'https://improve-bot.delightfulbush-65d4f852.westus3.azurecontainerapps.io/ac896cc987e2b4431e80e874471eb3dd50f4763a18f251c09b09c44c123858cf'

export const inviteToKriator = (mail) => {
  
  const response = axios.post(baseUrl, {
    mail,
    token: getToken()
  })

  return response
}