import axios from 'axios'
import { getToken } from '../getToken'
import { apiUrl } from '../utils/apiUrl'

const baseUrl = apiUrl('56e6d4a5422ab84b56e648abff0c0d469e4c9e6f2a46ac9e82b55665c46338f8') 
// const baseUrl = 'https://improve-bot.delightfulbush-65d4f852.westus3.azurecontainerapps.io/56e6d4a5422ab84b56e648abff0c0d469e4c9e6f2a46ac9e82b55665c46338f8'

export const getMenusByProfile = async () => {
  const { data } = await axios.get(baseUrl, {
    headers: {
      token: getToken()
    }
  })

  return data

}